import { computed, reactive, toRefs } from 'vue';
import { useSdk } from './use-mycure';
import { useFacilities } from './use-facilities';
import { omit } from 'lodash';
import { format, differenceInCalendarDays } from 'date-fns';
import { mapPersonalDetails } from '../utils/formatters';
const SERVICE_NAME = 'medical-encounters';

const state = reactive({
  encounters: [],

  allActiveEncounters: [],
  allFinishedEncounters: [],

  activeEncounters: [],
  activeEncountersTotal: 0,

  finishedEncounters: [],
  finishedEncountersTotal: 0,

  activeEncounter: null,
  recentEncounters: [],

  encounter: null,
  selectedPatientEncounters: [],

  totalNumberOfActiveEncounters: 0,
  totalNumberOfFinishedEncounters: 0,
});

const populatePatient = {
  method: 'findOne',
  service: 'personal-details',
  foreignKey: 'id',
  localKey: 'patient',
};

const populateCreator = {
  method: 'findOne',
  service: 'personal-details',
  foreignKey: 'id',
  localKey: 'createdBy',
};

const populateBillingInvoice = {
  method: 'findOne',
  service: 'billing-invoices',
  foreignKey: 'id',
  localKey: 'invoice',
};

const populateQueueItem = {
  method: 'findOne',
  service: 'queue-items',
  foreignKey: 'group',
  localKey: 'id',
};

function normalizeEncounter (encounter) {
  const patient = mapPersonalDetails(encounter?.$populated?.patient || {});
  const creator = mapPersonalDetails(encounter?.$populated?.creator || {});
  const invoice = encounter?.$populated?.invoice;
  const queueItem = encounter?.$populated?.queueItem;
  const finishedAt = encounter?.finishedAt;
  const createdAt = encounter?.createdAt;
  const isMoreThan24Hours = differenceInCalendarDays(new Date(), new Date(createdAt)) >= 1;
  let status = 'active';
  if (isMoreThan24Hours) status = 'inactive';
  if (finishedAt) status = 'finished';
  return {
    ...omit(encounter, ['$populated']),
    patient,
    creator,
    invoice,
    queueItem,
    formattedInvoiceDate: invoice?.createdAt ? format(new Date(invoice.createdAt), 'MMM dd, yyyy') : null,
    formattedCreatedAt: encounter?.createdAt ? format(new Date(encounter?.createdAt), 'MMM dd, yyyy') : null,
    formattedCreatedAtShort: encounter?.createdAt ? format(new Date(encounter?.createdAt), 'MM/dd/yy') : null,
    formattedFinishedAt: encounter?.finishedAt ? format(new Date(encounter?.finishedAt), 'MMM dd, yyyy') : null,
    formattedStatus: status,
  };
}

export function useEncounters () {
  const sdk = useSdk();
  const { getActiveFacility } = useFacilities();

  // TODO: this is wrong logic, replace with:
  // 1. fetch all encounters with at least 1 item without finishedAt
  const hasActiveEncounter = computed(() => !!state.activeEncounter);

  const isEncounterActive = computed(() => !state.encounter?.finishedAt);

  async function init (patient, selectedPatientOnly = false) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const query = {
      facility: facilityId,
      patient,
      $sort: {
        createdAt: -1,
      },
      $populate: {
        patient: populatePatient,
        creator: populateCreator,
      },
    };

    const result = await sdk?.list(SERVICE_NAME, query);
    if (selectedPatientOnly) {
      state.selectedPatientEncounters = result?.data.map(normalizeEncounter);
      return;
    }
    state.encounters = result?.data.map(normalizeEncounter);
  };

  async function get (id) {
    const query = {
      id,
      $populate: {
        patient: populatePatient,
        invoice: populateBillingInvoice,
        queueItem: populateQueueItem,
        creator: populateCreator,
      },
    };
    if (state.encounter?.id === id) {
      return state.encounter;
    }
    const result = await sdk?.get(SERVICE_NAME, query);
    if (!result) {
      state.encounter = null;
      return;
    }
    const normalized = normalizeEncounter(result);
    state.encounter = normalized;
    return normalized;
  };

  async function update (id, payload) {
    const result = await sdk?.update(SERVICE_NAME, id, payload);
    const normalized = normalizeEncounter(result);
    state.encounter = normalized;
  };

  async function create (patient, opts) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;

    if (!facilityId) return;

    const type = opts?.type || 'outpatient';

    const payload = {
      invoice: false,
      facility: facilityId,
      patient,
      type,
      invoice: true,
    };

    if (opts?.metadata) {
      payload.metadata = opts.metadata;
    }

    if (opts?.medicalRecords) {
      payload.medicalRecords = opts.medicalRecords;
    }

    const result = await sdk?.create(SERVICE_NAME, payload);
    const normalized = normalizeEncounter(result);
    state.recentEncounters.unshift(normalized);
    return normalized;
  };

  async function getActive (patient) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const query = {
      facility: facilityId,
      patient,
      finishedAt: {
        $exists: false,
      },
      $populate: {
        patient: populatePatient,
        invoice: populateBillingInvoice,
      },
    };
    const result = await sdk?.list(SERVICE_NAME, query);
    if (!result?.data?.length) {
      state.activeEncounter = null;
      return null;
    }
    const encounter = result?.data?.[0];
    const normalized = normalizeEncounter(encounter);
    state.activeEncounter = normalized;

    // check if encounter is already in the list
    const active = state.encounters.find((enc) => !enc.finishedAt);

    // add to list if not yet in the list
    if (!active) {
      state.encounters.unshift(normalized);
    }

    return normalized;
  };

  async function deleteEncounter (id) {
    await sdk?.delete(SERVICE_NAME, id);
    const index = state.encounters.findIndex((item) => item.id === id);
    state.encounters.splice(index, 1);
    state.activeEncounter = null;
  };

  async function listItems (opts) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const query = {
      facility: facilityId,
      $sort: {
        createdAt: -1,
      },
      $populate: {
        patient: populatePatient,
        invoice: populateBillingInvoice,
        queueItem: populateQueueItem,
        creator: populateCreator,
      },
      $limit: 10,
    };

    const result = await sdk?.list(SERVICE_NAME, { ...query, ...opts });
    // console.warn('encounters -> result', result.data);
    const data = result?.data?.map(normalizeEncounter);
    const sortedByQueueItem = data.sort((a, b) => {
      if (!a.queueItem) return 1;
      if (!b.queueItem) return -1;
      return a.queueItem.order - b.queueItem.order;
    });
    return {
      data: sortedByQueueItem,
      total: result?.total,
    };
  }

  async function listFinishedEncounters (opts, field = 'finishedEncounters') {
    const query = {
      finishedAt: {
        $exists: true,
      },
    };

    if (opts?.limit) query.$limit = opts.limit;

    const encounters = await listItems(query);
    state[field] = encounters?.data;
    state.finishedEncountersTotal = encounters?.total;
  };

  async function listActiveEncounters (opts, field = 'activeEncounters') {
    const query = {
      finishedAt: {
        $exists: false,
      },
    };

    if (opts?.limit) query.$limit = opts.limit;

    const encounters = await listItems(query);
    state[field] = encounters?.data;
    state.activeEncountersTotal = encounters?.total;
  }

  async function getTotalNumberOfEncounters (opts) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const activeQuery = {
      facility: facilityId,
      $total: true,
      finishedAt: {
        $exists: false,
      },
    };

    const closedQuery = {
      facility: facilityId,
      $total: true,
      finishedAt: {
        $exists: true,
      },
    };

    const [activeResult, closedResult] = await Promise.all([
      sdk?.list(SERVICE_NAME, activeQuery),
      sdk?.list(SERVICE_NAME, closedQuery),
    ]);

    state.totalNumberOfActiveEncounters = activeResult?.total;
    state.totalNumberOfFinishedEncounters = closedResult?.total;
  }

  function setEncounters (encounters = []) {
    state.encounters = encounters;
  }

  function setActivePatientEncounters (encounters = []) {
    state.selectedPatientEncounters = encounters;
  }

  function clearState () {
    state.encounters = [];
    state.activeEncounter = null;
    state.recentEncounters = [];
    state.encounter = null;
  }

  return {
    hasActiveEncounter,
    isEncounterActive,
    ...toRefs(state),
    normalizeEncounter,
    init,
    setActivePatientEncounters,
    get,
    update,
    create,
    getActive,
    deleteEncounter,
    listFinishedEncounters,
    listActiveEncounters,
    getTotalNumberOfEncounters,
    setEncounters,
    clearState,
  };
}
